import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item', 'image', 'itemTitle'];

  connect() {
    this.selectItem({ target: this.itemTargets[0] });
  }

  selectItem(event) {
    const selectedElement = event.target.closest(
      '[data-item-selector-target="item"]'
    );
    const selectedIndex = this.itemTargets.indexOf(selectedElement);

    this.itemTargets.forEach((item, index) => {
      const titleElement = this.itemTitleTargets[index];
      const imageElement = this.imageTargets[index];

      if (index === selectedIndex) {
        item.classList.add('shadow');
        titleElement.classList.add('text-primary-500');
        imageElement.classList.remove('hidden');
      } else {
        item.classList.remove('shadow');
        titleElement.classList.remove('text-primary-500');
        imageElement.classList.add('hidden');
      }
    });
  }
}
